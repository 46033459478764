import { AuthContextInterface } from "./context/auth/AuthContextInterface";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { Remove } from "@mui/icons-material";

/**
 * Type de menu gauche
 */
export const MENU_GAUCHE_ICONES = "MENU_ICONE";
export const MENU_GAUCHE_LISTE = "MENU_LISTE";
export let menuGaucheType = MENU_GAUCHE_LISTE; //type de menu (icon façon goat, ou liste façon oowi)
export const menuGaucheWidth = 190; //taille en largeur du menu de gauche (uniquement pour menu type liste)
export const defaultMenuIcon = { icon: Remove };

/**
 * Liste des pages à inclure dans le menu de gauche et haut
 */
interface pageLink {
  id: number;
  title: string;
  path: string;
  icon?: any;
  role?: string;
  subLinks?: pageSubLink[]; //ne fonctionne que pour le menu Liste
}

interface pageSubLink {
  id: number;
  title: string;
  path: string;
  role?: string;
}

export const pagesLinks: pageLink[] = [
  {
    id: 0,
    title: "Statistiques",
    path: "/",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
  {
    id: 1,
    title: "Admin",
    path: "/admins",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
  {
    id: 2,
    title: "Utilisateurs",
    path: "/users",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
  {
    id: 3,
    title: "Débats",
    path: "/debates",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
  {
    id: 4,
    title: "Notifications",
    path: "/notifications",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
  {
    id: 5,
    title: "Mentions Légales",
    path: "/mentions-legales",
    icon: SupervisedUserCircleIcon,
    role: "ROLE_ADMIN",
  },
];

/**
 * Liste des liens dans le menu utilisateur
 */
interface UserMenuLink {
  title: string;
  path: string;
}

export const userMenuLinks: UserMenuLink[] = [
  { title: "Deconnexion", path: "/logout" },
];

const date: Date = new Date();
const year: number = date.getFullYear();

/** Texte du footer, si laissé à null on aura pas de footer */
export const footerText = `Copyright © ${year} MLD`; //si null, pas de footer

/**
 * Titre du site, affiché à gauche du header
 * à faire évoluer quand on voudra un logo
 */
export const titreMenuHaut = "TALK BO";

/**
 * Fonction de renvoi du nom de l'utilisateur connecté
 * à modifier en fonction de la structure des objet User contenus dans AuthContextInterface
 * @param authContext
 */
export const getConnectedserName = (
  authContext: AuthContextInterface
): string => {
  return authContext.user?.firstName + " " + authContext.user?.lastName;
};
