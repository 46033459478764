import Box from "@mui/material/Box";
import {ReactNode} from "react";

const FormBox = (props: { children: ReactNode }) => {
    return (
        <Box
            component="form"
            display={"flex"}
            flexDirection={"column"}
            rowGap={"10px"}
            noValidate
            autoComplete="off"
            onSubmit={e => e.preventDefault()}
        >
            {props.children}
        </Box>
    )
}

export default FormBox;