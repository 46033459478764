import React, {createContext, ReactNode, useContext} from "react";
import {FormContextInterface} from "./FormContextInterface";
import useFormInterface from "../../hooks/form/useFormInterface";

const FormContext = createContext<FormContextInterface>({
    form: null
});

export interface IFormProvider {
    form: useFormInterface<any>;
    children: ReactNode;
}

export const FormProvider: React.FC<IFormProvider> = ({form, children}) => {
    return (
        <FormContext.Provider value={{form}}>
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => {
    return useContext(FormContext);
};
