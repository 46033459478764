import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAuthContext} from '../../context/auth/authContext';

const ProtectedRoute = () => {
    const auth = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        //console.log('ProtectedRoute',)
        if (!auth?.token) {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.token]);

    return (
        <Outlet/>
    )
}

export default ProtectedRoute;