import React from 'react';
import {TextField} from '@mui/material';
import {useFormContext} from "../../../context/form/formContext";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FormInput = (props: {
    nom: string,        //nom du champ dans le formulaire (id)
    type: string,       //type de champ (text, password, email, etc)
    label?: string,      //label du champ
    className?: string, //classe css additionnelle à appliquer au champ
    variant?: "standard" | "filled" | "outlined" | undefined, //varient du champ
    fullWidth?: boolean, //afficher le champ en full width
    onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void,
    multiline?: boolean,
    rows?: number,
    disabled?: boolean,
    maxLength?: number,
}): React.JSX.Element => {
    const form = useFormContext().form;
    const variant = props.variant ? props.variant : 'outlined';
    const avecErreur = !!form?.err[props.nom];
    /** Si on ne passe pas de label, on affiche le nom du champ  avec première lettre en majuscule*/
    const label = props.label ? props.label : props.nom[0].toUpperCase() + props.nom.substring(1)
    /* On passe automatiquement les input password en autocomplete */
    const autocomplete = props.type === 'password' ? 'on' : 'off';

    return (
        <TextField
            autoComplete={autocomplete}
            error={avecErreur}
            type={props.type}
            id={props.nom}
            label={label}
            size="small"
            value={form?.formData[props.nom] ? form?.formData[props.nom] : ''}
            onChange={e => form?.updateFormChamp(props.nom, e.target.value)}
            variant={variant}
            helperText={avecErreur ? form?.err[props.nom] : ''}
            fullWidth={props.fullWidth}
            onKeyDown={(event) =>
                props.onKeyDown ? props.onKeyDown(event) : null
            }
            multiline={props.multiline ? props.multiline : false}
            rows={props.rows ? props.rows : 1}
            disabled={props.disabled ? props.disabled : false}
            inputProps={props.maxLength ? {maxLength: props.maxLength} : undefined}
        />
    );
};

export default FormInput;