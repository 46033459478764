import React from 'react';
import FormInput from "./FormInput";


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordInput = (props: {
    nom: string,        //nom du champ dans le formulaire (id)
    label?: string,      //label du champ
    className?: string, //classe css additionnelle à appliquer au champ
    variant?: "filled" | "outlined" | "standard" | undefined, //variant du champ
    fullWidth?: boolean, //afficher le champ en full width
}) => {
    return (
        <FormInput
            type={"password"}
            nom={props.nom}
            label={props.label}
            className={props.className}
            variant={props.variant}
            fullWidth={props.fullWidth}
        />
    );
};

export default PasswordInput;
