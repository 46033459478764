import {CircularProgress} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import {useAuthContext} from "../../context/auth/authContext";
import {useNavigate} from "react-router-dom";

const HasRoleRoute = (props: {
    role: string | string[],
    redirectTo?: string,
    children: ReactNode
}) => {
    const authContext = useAuthContext();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (!authContext.hasRole(props.role)) {
            return navigate(props.redirectTo ? props.redirectTo : '/');
        }
        setLoading(false);
    }, [props.role]);

    if (loading) {
        return <CircularProgress/>
    }

    return <>{props.children}</>;
};

export default HasRoleRoute;