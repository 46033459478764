import {defaultMenuIcon, pagesLinks} from "../../../_Menus";
import ListItem from "@mui/material/ListItem";
import {Collapse, Link} from "@mui/material";
import {menusSublistBackgroundColor, menusTextColor} from "../../../_Theme";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import React from "react";
import {useLocation} from "react-router";
import {useAuthContext} from "../../../context/auth/authContext";

const MenuLinksList = () => {
    const authContext = useAuthContext();
    const location = useLocation();
    return (
        <List className={"menuGaucheList"}>
            {pagesLinks.map((pageLink, index) => (
                (pageLink.role === undefined || authContext.hasRole(pageLink.role)) &&
                <div key={index}>
                    <ListItem key={index} disablePadding>
                        <Link key={index} href={pageLink.path}
                              sx={{
                                  color: menusTextColor,
                                  cursor: "pointer",
                                  textDecoration: "none"
                              }}
                        >
                            <ListItemButton>
                                {pageLink.icon && <pageLink.icon sx={{color: menusTextColor}} color={location.pathname.includes(pageLink.path) ? "primary" : "inherit"}/>}
                                {!pageLink.icon && <defaultMenuIcon.icon sx={{color: menusTextColor}} color={location.pathname.includes(pageLink.path) ? "primary" : "inherit"}/>}
                                <ListItemText primary={pageLink.title}/>
                            </ListItemButton>
                        </Link>
                    </ListItem>
                    {pageLink.subLinks && <Collapse in={true} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding
                              sx={{backgroundColor: menusSublistBackgroundColor}}
                        >
                                {pageLink.subLinks.map((subLink, index) => (

                                        (subLink.role === undefined || authContext.hasRole(subLink.role)) &&
                                            <Link key={index} href={subLink.path}
                                                  sx={{
                                                      color: menusTextColor,
                                                      cursor: "pointer",
                                                      textDecoration: "none"
                                                  }}
                                            >
                                                <ListItemButton className={"menuGaucheSubMenuListItemButton"}
                                                                key={index}
                                                                sx={{pl: 4}}>
                                                    <ListItemButton>
                                                        <ListItemText primary={subLink.title}/>
                                                    </ListItemButton>
                                                </ListItemButton>
                                            </Link>
                                ))}
                        </List>
                    </Collapse>}
                </div>
            ))}
        </List>
    )
}

export default MenuLinksList;