import React from "react";
import Box from "@mui/material/Box";
import {
  footerText,
  MENU_GAUCHE_LISTE,
  menuGaucheType,
  menuGaucheWidth,
} from "../../_Menus";

const Footer = () => {
  const renderDevElements = () => {
    if (process.env.REACT_APP_ENV === "prod") {
      return;
    }
    return (
      <>
        <Box display={"inline"}>
          {" "}
          - environnement {process.env.REACT_APP_ENV} -{" "}
        </Box>
        <Box sx={{ display: { xs: "none", xxl: "inline" } }}>
          XX-LARGE (XXL)
        </Box>
        <Box sx={{ display: { xs: "none", xl: "inline", xxl: "none" } }}>
          X-LARGE (XL)
        </Box>
        <Box sx={{ display: { xs: "none", lg: "inline", xl: "none" } }}>
          LARGE (LG)
        </Box>
        <Box sx={{ display: { xs: "none", md: "inline", lg: "none" } }}>
          MEDIUM (M)
        </Box>
        <Box sx={{ display: { xs: "none", sm: "inline", md: "none" } }}>
          SMALL (SM)
        </Box>
        <Box sx={{ display: { xs: "inline", sm: "none" } }}>
          X-SMALL (Defaut)
        </Box>
      </>
    );
  };

  if (!footerText) {
    return null;
  }

  return (
    <footer className="py-0 bg-light fixed-bottom d-flex justify-content-center">
      {footerText}
      {renderDevElements()}
    </footer>
  );
};
export default Footer;
