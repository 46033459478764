import axios, { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContextInterface } from "../../context/auth/AuthContextInterface";
import ConcurrentUriInterface from "./ConcurrentUriInterface";

export const useApiService = (authContext: AuthContextInterface) => {
  const apiUri = process.env.REACT_APP_API_URI;
  let concurrent: ConcurrentUriInterface = {};
  const promiseReuseLimit = 2000;
  const navigate = useNavigate();

  /**
   * @param noAuth
   * @param fileUpload
   */
  const getConfig = (noAuth: boolean = false, fileUpload: boolean = false) => {
    if (noAuth) {
      return {
        headers: {
          "Content-Type": fileUpload ? "text/plain" : "application/json",
        },
      };
    }

    if (!authContext.token) {
      navigate("/login");
    }

    return {
      headers: {
        "API-TALK-KEY": process.env.REACT_APP_API_TALK_KEY,
        "Content-Type": fileUpload ? "text/plain" : "application/json",
        authorization: "Bearer " + authContext.token,
      },
    };
  };

  /**
   * GET générique avec partage des promises dans un temps limité
   * @param uri
   * @param noAuth
   * @returns {Promise<any>}
   */
  const get = async (uri: string, noAuth = false): Promise<any> => {
    //si on a une promise en mémoire agée de moins de 2 secondes on l'utilise
    if (concurrent.hasOwnProperty(uri)) {
      //si la promise est encore valide, on la renvoie
      if (concurrent[uri].limit > Date.now()) {
        return Promise.resolve(concurrent[uri].value);
      }
      //si on en trouve une obsolète, on la delete
      delete concurrent[uri];
    }

    //si on n'a pas de promise reutilisable, on lance un appel et on met sa promise en mémoire avant de la renvoyer
    let p = getCall(uri, noAuth).then(function (result) {
      concurrent[uri] = {
        limit: Date.now() + promiseReuseLimit,
        value: result,
      };
      return result;
    });
    concurrent[uri] = { limit: Date.now() + promiseReuseLimit, value: p };
    return p;
  };

  const getListe = (uri: string, search: { [x: string]: string }) => {
    let uriParams = "";
    let cnt = 0;

    for (const property in search) {
      uriParams += cnt++ === 0 ? "?" : "&";
      uriParams += property + "=" + search[property];
    }

    return get(uri + uriParams);
  };

  /**
   *
   * @param uri
   * @returns {Promise<Response>}
   */
  const fetchCall = async (uri: string): Promise<Response> => {
    return fetch(apiUri + uri, {
      headers: {
        authorization: "Bearer " + authContext.token,
      },
    })
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  /**
   * Upload Fichier
   * @param uri
   * @param file
   * @param noAuth
   */
  const fileUpload = async (
    uri: string,
    file: File,
    noAuth = false
  ): Promise<AxiosResponse> => {
    const data = new FormData();
    data.append("file", file, file.name);

    return axios
      .post(apiUri + uri, data, getConfig(noAuth, true))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  /**
   *
   * @param uri
   * @param noAuth
   * @returns {Promise<AxiosResponse<*>>}
   */
  const getCall = async (
    uri: string,
    noAuth = false
  ): Promise<AxiosResponse> => {
    return axios
      .get(apiUri + uri, getConfig(noAuth))
      .then(function (response) {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  /**
   * POST générique
   * @param uri
   * @param data
   * @param noAuth
   * @returns {Promise<AxiosResponse<*>>}
   */
  const post = async (
    uri: string,
    data: object,
    noAuth = false
  ): Promise<AxiosResponse> => {
    const json_data = JSON.stringify(data);
    return axios
      .post(apiUri + uri, json_data, getConfig(noAuth))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err.response);
        return err.response;
      });
  };

  /**
   * PUT générique
   * @param uri
   * @param data
   * @param noAuth
   * @returns {Promise<AxiosResponse<*>>}
   */
  const put = async (
    uri: string,
    data: object,
    noAuth = false
  ): Promise<AxiosResponse> => {
    const json_data = JSON.stringify(data);
    return axios
      .put(apiUri + uri, json_data, getConfig(noAuth))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  /**
   * DELETE générique
   * @param uri
   * @param noAuth
   * @returns {Promise<AxiosResponse<*>>}
   */
  const deleteCall = async (
    uri: string,
    noAuth = false
  ): Promise<AxiosResponse> => {
    return axios
      .delete(apiUri + uri, getConfig(noAuth))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const downloadFile = (uri: string): void => {
    fetchCall(uri).then((response) => {
      // @ts-ignore
      let x = [...response.headers];
      let headerFilename = undefined;
      let fileName = "unknown";
      let fileExtension = "ukn";

      let db = require("mime-db");
      x.forEach((item) => {
        if (item[0] === "content-type") {
          let ext = db[item[1]];
          if (ext && ext.extensions && ext.extensions.length > 0) {
            fileExtension = ext.extensions[0];
          }
        }

        if (item[0] === "content-disposition") {
          let value = item[1];
          headerFilename = value.split('"')[1];
        }
      });

      if (headerFilename) {
        fileName = headerFilename;
      } else {
        fileName = fileName + "." + fileExtension;
      }

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  };

  return {
    get,
    getListe,
    post,
    put,
    deleteCall,
    fileUpload,
    fetchCall,
    downloadFile,
  };
};
