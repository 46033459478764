export default interface DecodedTokenInterface {
    exp: number;            //expiration, l'heure à laquelle le token expire
    iat: number;            //issued at, l'heure à laquelle on a fabriqué le token la première fois
    roles: Array<string>;   //la liste des roles du user, dans notre appli il n'y en aura toujours qu'un
    username: string;       //l'identifiant du user connecté
    prenom: string;         //le rpénom de l'utilisateur
    nom: string;            //le nom de l'utilisateur
    [key: string]: any;     //Anything
}


export const defaultDecodedToken: DecodedTokenInterface = {
    iat: 1480696020,
    exp: 1574162820,
    roles: [
        '',
    ],
    username: 'deconnecté',
    prenom: 'Déco',
    nom: 'Necté',
}