import {Drawer} from "@mui/material";
import {menuGaucheWidth} from "../../../_Menus";
import Box from "@mui/material/Box";
import Toolbar from '@mui/material/Toolbar';
import {menusBackgroundColor, menusTextColor} from "../../../_Theme";
import './MenuGaucheListeStyle.css';
import MenuLinksList from "./MenuLinksList";
import React from "react";

const MenuGaucheListe = () => {
    return (
        <Box
            sx={{
                minWidth: menuGaucheWidth,
                width: menuGaucheWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: menuGaucheWidth,
                    backgroundColor: menusBackgroundColor,
                    color: menusTextColor,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Toolbar/>
            <Box sx={{overflow: 'auto'}} className={"menuContainer"}>
                <MenuLinksList/>
            </Box>
        </Box>
    );
}

export default MenuGaucheListe;