import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {useAuthContext} from "../../../context/auth/authContext";
import {ArrowDropDown, StarBorder, TramSharp} from "@mui/icons-material";
import {Collapse, Link, ListItemIcon} from "@mui/material";
import {defaultMenuIcon, getConnectedserName, pagesLinks, titreMenuHaut, userMenuLinks} from "../../../_Menus";
import {menusSublistBackgroundColor, menusTextColor} from "../../../_Theme";
import MenuLinksList from "./MenuLinksList";
import "./MenuGaucheListeStyle.css";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import logo from "../../../logo_talk.svg";


const MenuHaut = () => {
    const authContext = useAuthContext();
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const location = useLocation();
    const navigate = useNavigate();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (<AppBar position="fixed" sx={{zIndex: 9000}}>
            <Container maxWidth={false} className={"toolbar"}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                       <img src={logo} alt="logo" />
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                                zIndex: 10000
                            }}
                        >
                            {pagesLinks.map((pageLink, index) => (
                                (pageLink.role === undefined || authContext.hasRole(pageLink.role)) &&
                                <div key={index}>
                                    <ListItemButton onClick={() => navigate(pageLink.path)}>
                                        {pageLink.icon &&
                                            <pageLink.icon
                                                color={location.pathname.includes(pageLink.path) ? "primary" : "inherit"}
                                            />}
                                        {!pageLink.icon &&
                                            <defaultMenuIcon.icon
                                                color={location.pathname.includes(pageLink.path) ? "primary" : "inherit"}
                                            />}
                                        <ListItemText primary={pageLink.title} sx={{paddingLeft: "0.5em"}}/>
                                    </ListItemButton>
                                    {pageLink.subLinks &&
                                        <Collapse key={pageLink.id + "_submenu"} in={true} timeout="auto" unmountOnExit>
                                            {pageLink.subLinks.map((subLink, index) => (
                                                    (subLink.role === undefined || authContext.hasRole(subLink.role)) &&
                                                    <ListItemButton key={index} sx={{pl: 5}}
                                                                    onClick={() => navigate(subLink.path)}>
                                                        <ListItemText primary={subLink.title}
                                                                      sx={{paddingLeft: "0.5em"}}/>
                                                    </ListItemButton>
                                                )
                                            )}
                                        </Collapse>
                                    }
                                </div>
                            ))}
                        </Menu>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}/>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Paramètres">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0, color: menusTextColor}}>
                                {getConnectedserName(authContext)} <ArrowDropDown/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {userMenuLinks.map((item) => (
                                <Link key={item.path} href={item.path} underline="hover" color="inherit">
                                    <MenuItem onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">
                                            {item.title}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MenuHaut;