import Login from "./views/_Auth/Login";
import React from "react";

const LazyLogout = React.lazy(() => import("./views/_Auth/Logout"));

const LazyDashboardPage = React.lazy(
  () => import("./views/Dashboard/Dashboard")
);

//Admin
const LazyAdminsList = React.lazy(() => import("./views/Admins/AdminsList"));
const LazyAdminsForm = React.lazy(() => import("./views/Admins/AdminsForm"));

//Users
const LazyUsersList = React.lazy(() => import("./views/Users/UsersList"));
const LazyUsersForm = React.lazy(() => import("./views/Users/UsersForm"));
const LazyUsersListMessage = React.lazy(
  () => import("./views/Users/UsersListMessage")
);

//Debates
const LazyDebatesList = React.lazy(() => import("./views/Debates/DebatesList"));

//Messages
const LazyMessagesList = React.lazy(
  () => import("./views/Messages/MessagesList")
);

//Notifications
const LazyNotificationsList = React.lazy(
  () => import("./views/Notifications/NotificationsList")
);
const LazyNotificationsForm = React.lazy(
  () => import("./views/Notifications/NotificationsForm")
);

//Mentions légales
const LazyMentionList = React.lazy(
  () => import("./views/Mentions/MentionList")
);
const LazyMentionForm = React.lazy(
  () => import("./views/Mentions/MentionForm")
);

const LazyMention = React.lazy(() => import("./views/Mentions/Mention"));

export interface RoutesInterface {
  path: string;
  element: React.ReactNode;
  index?: boolean;
  description?: string;
  children?: RoutesInterface[];
  role?: string | string[];
  noRoleRedirectUri?: string;
}

export const unprotectedRoutes: RoutesInterface[] = [
  {
    path: "login",
    description: "Page de connexion",
    element: <Login />,
  },
  {
    path: "/page/:slug",
    description:
      "Conditions générales d’utilisation et Politiques de confidentialité",
    element: <LazyMention />,
  },
];

export const protectedRoutes: RoutesInterface[] = [
  {
    path: "logout",
    description: "Déconnexion",
    element: <LazyLogout />,
  },
  {
    index: true,
    path: "",
    description: "Home",
    element: <LazyDashboardPage />,
  },
  {
    path: "/admins",
    description: "Liste des admintrateurs",
    role: ["ROLE_ADMIN"],
    element: <LazyAdminsList />,
  },
  {
    path: "/admins/new",
    description: "Créer un admintrateur",
    role: ["ROLE_ADMIN"],
    element: <LazyAdminsForm />,
  },
  {
    path: "/admins/:id/edit",
    description: "Edit admintrateur",
    role: ["ROLE_ADMIN"],
    element: <LazyAdminsForm />,
  },
  {
    path: "/users",
    description: "Liste des utilisateur",
    role: ["ROLE_ADMIN"],
    element: <LazyUsersList />,
  },
  {
    path: "/users/messages/:id",
    description: "Liste des messages par utilisateur",
    role: ["ROLE_ADMIN"],
    element: <LazyUsersListMessage />,
  },
  {
    path: "/debates",
    description: "Liste des débats",
    role: ["ROLE_ADMIN"],
    element: <LazyDebatesList />,
  },
  {
    path: "/messages/:id",
    description: "Liste des messages",
    role: ["ROLE_ADMIN"],
    element: <LazyMessagesList />,
  },
  {
    path: "/notifications",
    description: "Liste des notifications",
    role: ["ROLE_ADMIN"],
    element: <LazyNotificationsList />,
  },
  {
    path: "/notifications/new",
    description: "create notification",
    role: ["ROLE_ADMIN"],
    element: <LazyNotificationsForm />,
  },
  {
    path: "/mentions-legales",
    description: "Liste des mentions légales",
    role: ["ROLE_ADMIN"],
    element: <LazyMentionList />,
  },
  {
    path: "/mentions-legales/new",
    description: "create mention légales",
    role: ["ROLE_ADMIN"],
    element: <LazyMentionForm />,
  },
  {
    path: "/mentions-legales/:id/edit",
    description: "Edit mention légales",
    role: ["ROLE_ADMIN"],
    element: <LazyMentionForm />,
  },
];
