import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Paper, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import MenuHaut from "./Menu/MenuHaut";
import MenuGaucheIcones from "./Menu/MenuGaucheIcones";
import Footer from "./Footer";
import { menusBackgroundColor } from "../../_Theme";
import {
  MENU_GAUCHE_ICONES,
  MENU_GAUCHE_LISTE,
  menuGaucheType,
  menuGaucheWidth,
} from "../../_Menus";
import MenuGaucheListe from "./Menu/MenuGaucheListe";
import Grid2 from "@mui/material/Unstable_Grid2";

const MainContent = styled(Paper)(({ theme }) => ({
  minHeight: "80vh",
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  marginTop: "70px",
}));

const PageLayout = () => {
  return (
    <Grid container>
      <MenuHaut />
      <Grid
        container
        display={"flex"}
        sx={{ width: "100%" }}
        flexWrap={"nowrap"}
      >
        <Grid2
          sx={{
            display: { xs: "none", md: "block" },
            backgroundColor: menusBackgroundColor,
            minHeight: "100%",
            minWidth: menuGaucheWidth,
          }}
        >
          {menuGaucheType === MENU_GAUCHE_ICONES && <MenuGaucheIcones />}
          {menuGaucheType === MENU_GAUCHE_LISTE && <MenuGaucheListe />}
        </Grid2>
        <Grid2 flexGrow={1}>
          <MainContent>
            <Grid2 container justifyContent="center">
              <Outlet />
            </Grid2>
          </MainContent>
          <Box display={"flex"} justifyContent={"center"}>
            <Footer />
          </Box>
        </Grid2>
      </Grid>
    </Grid>
  );
};
export default PageLayout;
