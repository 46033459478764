import { AxiosResponse } from "axios";
import { useApiService } from "../api/useApiService";
import { AuthContextInterface } from "../../context/auth/AuthContextInterface";
import LoginFormInterface from "./LoginFormInterface";

export const useAuthService = (authContext: AuthContextInterface) => {
  const api = useApiService(authContext);

  const loginEndpoint =
    process.env.REACT_APP_API_LOGIN_ENDPOINT || "login_check";
  const currentUserEndpoint =
    process.env.REACT_APP_API_CURRENT_USER_ENDPOINT || "current-user";

  /**
   * @param credentials
   * @returns {Promise<Response>}
   */
  const loginUser = async (
    credentials: LoginFormInterface
  ): Promise<AxiosResponse> => {
    const response = await api.post(loginEndpoint, credentials);

    if (response.status === 200) {
      authContext.setToken(response.data.token);
    }
    return response;
  };

  /**
   * Chargement de l'utilisateur courrant
   */
  const loadCurrentUser = () => {
    authContext.setLoadingUser(true);
    api.get(currentUserEndpoint).then((response) => {
      if (response.status === 200) {
        authContext.replaceConnectedUser(response.data);
      }
      authContext.setLoadingUser(false);
    });
  };

  /**
   * Deconnexion utilisateur
   */
  const logoutUser = () => {
    authContext.logout();
  };

  return {
    loginUser,
    logoutUser,
    loadCurrentUser,
  };
};
